// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-page-js": () => import("./../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-definitions-page-js": () => import("./../src/templates/definitions-page.js" /* webpackChunkName: "component---src-templates-definitions-page-js" */),
  "component---src-templates-definitions-post-js": () => import("./../src/templates/definitions-post.js" /* webpackChunkName: "component---src-templates-definitions-post-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-legal-page-js": () => import("./../src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-parents-page-js": () => import("./../src/templates/parents-page.js" /* webpackChunkName: "component---src-templates-parents-page-js" */),
  "component---src-templates-payment-success-page-js": () => import("./../src/templates/payment-success-page.js" /* webpackChunkName: "component---src-templates-payment-success-page-js" */),
  "component---src-templates-standard-page-js": () => import("./../src/templates/standard-page.js" /* webpackChunkName: "component---src-templates-standard-page-js" */),
  "component---src-templates-syllabus-page-js": () => import("./../src/templates/syllabus-page.js" /* webpackChunkName: "component---src-templates-syllabus-page-js" */),
  "component---src-templates-syllabus-post-js": () => import("./../src/templates/syllabus-post.js" /* webpackChunkName: "component---src-templates-syllabus-post-js" */),
  "component---src-templates-teachers-page-js": () => import("./../src/templates/teachers-page.js" /* webpackChunkName: "component---src-templates-teachers-page-js" */),
  "component---src-templates-tutoring-page-js": () => import("./../src/templates/tutoring-page.js" /* webpackChunkName: "component---src-templates-tutoring-page-js" */),
  "component---src-templates-vocab-page-js": () => import("./../src/templates/vocab-page.js" /* webpackChunkName: "component---src-templates-vocab-page-js" */),
  "component---src-templates-vocab-post-js": () => import("./../src/templates/vocab-post.js" /* webpackChunkName: "component---src-templates-vocab-post-js" */)
}

